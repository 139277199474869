@use "styles/variables" as *;

.wrapper {
  display: flex;
}

.form {
  padding: 30px 30px;
  height: 100vh;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    color: $font-clear;
    margin-bottom: 20px;
    line-height: 22px;
  }

  h4 {
    font-size: 14px;
  }

  a {
    color: $font-base;
    font-family: "Roboto_bold";
  }

  form {
    width: 80%;
    margin: auto;
  }

  button {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  p {
    color: $font-clear;
  }
}

.boxLogo {
  display: flex;
  align-items: center;
  font-family: "Roboto_regular";

  span {
    font-size: 1.5rem;
    font-weight: 900;
    margin-left: 10px;
  }
}

.logo {
  width: 40px;
  height: 40px;

  path {
    fill: $secondary-color;
  }
}

.illustrationContainer {
  background: #f8f8fa;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 30px;

  img {
    margin-bottom: 50px;
    width: 648px;
    height: 378.6px;
    filter: drop-shadow(0px 30px 30px rgba(0, 0, 0, 0.2));
    z-index: 1;
    border-radius: 10px;
  }

  h5 {
    font-size: 40px;
    text-align: center;
    font-family: "Roboto_bold";
    width: 90%;

    span {
      color: transparent;
      background-clip: text;
      background-image: $primary-gradient-2;
    }
  }

  h6 {
    width: 80%;
    margin-top: 20px;
    text-align: center;
    color: $font-clear;
  }
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column-reverse;
  }

  .form {
    justify-content: flex-start;
    width: 100%;
    min-height: 100vh;
    border-radius: 20px;
    height: fit-content;
    background-color: $clear-bg;
    padding: 0px;

    form {
      padding: 0px 20px;
      width: 100%;
    }

    h4 {
      padding: 0px 20px;
      padding-bottom: 20px;
      margin-top: 10vh;
    }
  }

  .boxLogo {
    padding: 20px 20px;
    background-color: $secondary-color;
    color: $font-white;
    margin-bottom: 10vh;

    span {
      margin-left: 12px;
    }
  }

  .logo path {
    fill: $font-white;
  }

  .illustrationContainer {
    display: none;
    padding-top: 100px;
    width: 100%;
    background-color: $clear-bg;

    img {
      width: 250.55px;
      height: 170.275px;
      filter: drop-shadow(0px 50px 30px rgba(0, 0, 0, 0.2));
      margin-bottom: 5px;
    }

    h5 {
      font-size: 10px;
    }
  }
}
