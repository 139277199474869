@use "styles/variables" as *;

.selectionsWrapper {
  margin-top: 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding-bottom: 10px;
}

.selection {
  padding-right: 26px;
  margin-right: 20px;
  border-radius: 10px;
  font-family: "Roboto_bold";
  color: $font-clear;
  cursor: pointer;
  position: relative;
  transition: 0.3s;

  &::after {
    transition: 0.3s;
    content: "";
    position: absolute;
    bottom: -11px;
    left: 0;
    width: 0;
    height: 2px;
    background: $primary-color;
  }

  &:hover {
    color: $primary-color;
  }
}

.userSelect {
  color: $primary-color;
  transition: 0.3s;

  &::after {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 0;
    width: calc(100% - 26px);
    height: 2px;
    background: $primary-color;
  }
}

@media screen and (max-width: 768px) {
  .selectionsWrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $dark-bg;
    border-radius: 6px;
    width: 100%;
    padding: 4px;
    margin-top: 0;
    border-bottom: none;
  }

  .selection {
    flex: 1;
    padding: 5px;
    border-radius: 6px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      margin-right: 0;
    }

    &::after {
      display: none;
    }
  }

  .userSelect {
    background-color: $clear-bg;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  }
}
