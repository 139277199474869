@use "styles/variables" as *;
@use "styles/animations";

.wrapper {
  display: flex;
  flex-direction: column;

  button {
    margin-top: 20px;
  }
}

.container {
  transition: all 0.3s ease-in-out;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;

  // p {
  //   margin-top: 20px;
  //   border: 1px solid variables.$border-color;
  //   padding: 30px 30px;
  //   border-radius: 0 20px 20px 20px;
  //   line-height: 24px;
  //   margin-left: 25px;
  //   white-space: pre-line !important;
  //   overflow: hidden;
  // }
}

.lottie__wrapper {
  flex-shrink: 0;
  // background-color: aqua;
  position: relative;
  height: 100px;
  width: 70px;
  border-radius: 0px 0px 0% 0%;
  overflow: hidden;
}

.lottie {
  bottom: 0;
  position: absolute;
  flex-shrink: 0;
  padding: 10px;
  background: $primary-gradient;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  // box-shadow: 0px 2px 15px rgba(39, 152, 252, 0.3);

  > div {
    transform: translate(-50%, -50%);
    width: 90px;
    height: 90px;
    position: absolute;
    top: 10%;
    left: 50%;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.35));
    animation: topOn 0.7s ease-in-out;
  }
}

.markdownContainer {
  margin-top: 20px;
  border: 1px solid $border-color;
  padding: 30px 30px;
  border-radius: 0 20px 20px 20px;
  line-height: 24px;
  margin-left: 25px;
  white-space: pre-line !important;
  overflow: hidden;
}

.reactMarkDown {
  h1 {
    font-size: 22px;
    margin-bottom: 0px;
  }

  h2,
  h3 {
    border: none !important;
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 22px;
  }

  li {
    margin-bottom: 0px;
    line-height: 1.2;
    list-style: none;
    position: relative;
    margin-left: 20px;
    color: $font-text;

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translate(0%, -50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $primary-color;
    }
  }

  p {
    margin-top: 0 !important;
    line-height: 1.3;
    color: $font-text;
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0;

    p {
      margin-top: 15px;
      margin-left: 0;
      padding: 30px 20px;
      border-radius: 20px;
      border: 1px solid $border-color;
    }
  }

  .markdownContainer {
    padding: 10px;
    margin-left: 0;
  }

  .lottie__wrapper {
    margin-top: 0px;
    border-radius: 0px 0px 0% 0%;
  }
}
