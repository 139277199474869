@use "styles/variables";

@keyframes openOn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.container {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding: 20px;
  padding-top: 90px;
  width: 50%;
  background-color: variables.$clear-bg;
  white-space: pre-line;
  border-left: 1px solid variables.$border-color;
  border-radius: 0 20px 20px 0px;
  animation: openOn 0.3s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }
}

.toClose {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 35px;
  color: variables.$font-clear;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: variables.$primary-color;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    border-radius: 0;
  }
}
