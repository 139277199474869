@use "styles/variables" as *;

.wrapper {
  h3 {
    font-family: "Roboto_bold";
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  h4 {
    color: $font-clear;
    margin-bottom: 25px;
  }
}

.billingWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $border-color;
  padding-bottom: 25px;
}

.section {
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 25px;
  margin-bottom: 20px;

  button {
    width: 80%;
  }

  &__title {
    width: 50%;
    margin-right: 20px;

    h5 {
      font-family: "Roboto_bold";
      font-size: 16px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      color: $font-clear;
    }
  }

  &__right {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .billingWrapper {
    flex-direction: column;
  }

  .section {
    flex-direction: column;
    width: 100%;

    &__title {
      width: 100%;
      margin-bottom: 15px;
    }

    &__right {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
