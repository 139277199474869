@use "styles/variables";

.container {
  position: relative;
  transition: 0.3s;
  margin-top: 4px;

  > input,
  textarea {
    background-color: transparent;
    border: 1px solid variables.$border-color;
    border-radius: 6px;
    padding: 10px 8px;
    width: 100%;
    z-index: 1;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  textarea {
    font-family: "Roboto_regular";
    resize: vertical;
    height: 100px;
  }
}

.container__focus {
  padding-top: 20px;

  input,
  textarea {
    transition: all 0.3s ease;
    border: 1px solid variables.$primary-color;
  }
}

.container__complet {
  padding-top: 20px;
}

.label {
  position: absolute;
  transition: all 0.3s ease;
  left: 8px;
  top: 10px;
  z-index: 0;
  color: variables.$font-clear;
  cursor: text;
}

.label__focus {
  font-weight: bold;
  color: variables.$primary-color;
  left: 0;
  font-size: 14px;
  transition: all 0.3s ease;
  top: 0;
  cursor: default;
}

.label__complet {
  font-weight: bold;
  color: variables.$font-base;
  left: 0;
  font-size: 14px;
  transition: all 0.3s ease;
  top: 0;
  cursor: default;
}

@media screen and (max-width: 768px) {
  .label__focus > p > span {
    display: none;
  }

  .label__complet > p > span {
    display: none;
  }
}
