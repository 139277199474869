@use "styles/variables" as *;

.container {
  margin: 0 18px;
  color: $font-clear;
  height: 84vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: 0.3s;
  width: 216px;

  &:hover {
    .chevron {
      opacity: 1;
    }
  }

  span {
    transition: 0.3s;
    margin-left: 12px;
    margin-right: 50px;
    white-space: nowrap;
  }
}

.billingButton {
  padding: 12px 0 !important;
}

.chevron {
  border-radius: 50%;
  border: 1px solid $font-clear;
  position: absolute;
  top: -55px;
  right: 0px;
  font-size: 40px;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;

  &:hover {
    color: $primary-color;
    filter: drop-shadow(0px 2px 2px rgba(89, 167, 84, 0.15));
  }
}

.chevron__right {
  transform: rotate(180deg);
}

.box {
  transition: 0.3s;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    transition: 0.3s;

    color: $primary-color;
    filter: drop-shadow(0px 2px 2px rgba(89, 167, 84, 0.15));

    span {
      transition: 0.3s;
      color: $primary-color;
      filter: drop-shadow(0px 2px 2px rgba(89, 167, 84, 0.15));
    }
  }

  span {
    margin-left: 10px;
    margin-right: 30px;
  }

  svg {
    font-size: 22px;
    flex-shrink: 0;
  }
}

.boxLogo {
  transition: 0.3s;
  // margin-left: 20px;
  display: flex;
  align-items: center;
  color: $font-white;
  position: relative;
  align-self: center;

  span {
    margin-left: 15px;
    margin-right: 30px;

    &:nth-child(3) {
      font-size: 12px;
      border: 1px solid $primary-color;
      border-radius: 25px;
      padding: 2px 12px;
      right: 0px;
      top: 34px;
      position: absolute;
      color: $primary-color;
      filter: drop-shadow(0px 0px 2px rgba(39, 152, 252, 0.35));
      box-shadow: 0px 0px 4px rgba(39, 152, 252, 0.25);
    }
  }
}

.logo {
  flex-shrink: 0;
  margin-left: 0px;
  color: $font-white;
  transition: 0.3s;
  width: 30px;
  height: 30px;

  path {
    fill: $font-white;
  }
}

.lastMenu {
  position: relative;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: -20px;
    width: 100%;
    margin-bottom: 20px;
    height: 1px;
    background-color: $font-clear;
  }
}

.menu {
  transition: 0.3s;

  li {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 6px;
    margin-bottom: 30px;
    cursor: pointer;
    transition: 0.3s;
    list-style-type: none;
    width: 160px;

    &:hover {
      color: $primary-color;
      filter: drop-shadow(0px 2px 2px rgba(89, 167, 84, 0.15));
    }
  }

  svg {
    font-size: 22px;
    flex-shrink: 0;
  }
}

/* On Close */
.container__close {
  transition: 0.3s;
  align-items: center;
  width: 45px;

  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .box {
    transition: 0.3s;
    width: 50px;
    justify-content: center;
  }

  .boxLogo {
    width: 50px;

    > span:nth-child(3) {
      display: none;
    }
  }

  .logo {
    margin-left: 10px;
  }

  li {
    width: 20px;
  }

  span {
    transition: 0.3s;
    width: 0%;
    overflow: hidden;
    opacity: 0;
    margin-right: 0;
    margin-left: 0;
  }

  .billingButton {
    padding: 10px 12px !important;

    svg {
      font-size: 22px;
    }
  }
}
