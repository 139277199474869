@use "styles/variables" as *;
@use "styles/animations";

.CustomButton {
  cursor: pointer;
  border: none;
  padding: 15px 100px;
  font-size: 16px;
  color: $font-white;
  border-radius: 10px;
  background: $primary-gradient;
  transition: all 0.3s ease-in-out;
  display: none;
  box-shadow: 0px 4px 10px rgba(39, 152, 252, 0.4);
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0px 4px 12px rgba(39, 152, 252, 0.8);
  }

  &__display {
    display: flex;
    animation: opacityOn 0.4s ease-in-out;
  }

  &:disabled {
    cursor: initial;
  }
}

.loader {
  font-size: 16px;
  height: 20px;

  svg {
    animation: turnOn 2s linear infinite;
    font-size: 20px;
  }
}

.color__red {
  background: $red-color !important;
  box-shadow: 0 2px 10px rgba(241, 76, 97, 0.4);

  &:hover {
    box-shadow: 0 2px 10px rgba(241, 76, 97, 0.8);
  }
}

.color__green {
  background: $green-color !important;

  box-shadow: 0 2px 10px rgba(32, 193, 144, 0.4);

  &:hover {
    box-shadow: 0 2px 10px rgba(32, 193, 144, 0.8);
  }
}
