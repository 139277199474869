@use "styles/variables";

@keyframes widthOn {
  0% {
    width: 0%;
  }
  100% {
    width: 940%;
  }
}

@keyframes widthOnMobile {
  0% {
    width: 0%;
  }
  100% {
    width: 660%;
  }
}

.file {
  padding: 15px 0;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid variables.$border-color;
  }

  &__category {
    display: flex;
    align-items: flex-end;
    flex: 0.31;

    svg {
      font-size: 20px;
      flex-shrink: 0;
      color: rgb(129, 195, 253);
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.08));
    }

    p {
      margin-left: 8px;
      font-family: "Roboto_bold";
      white-space: nowrap;
      margin-right: 10px;
    }
  }

  > div:first-child {
    display: flex;
    align-items: center;
    width: 95%;
    max-width: 95%;
    display: flex;
  }

  > div > div:first-child {
    flex: 0.2;
  }

  > div > p:nth-child(2) {
    flex: 0.7;
    overflow: hidden;
    margin-right: 10px;
    color: variables.$font-clear;
  }

  > div > p:nth-child(3) {
    flex: 0.1;
    color: variables.$font-clear;
  }

  > svg {
    font-size: 18px;
  }

  &__modify {
    flex: 0.03;
    padding: 3px 0;
    display: flex;
    justify-content: center;
    position: relative;

    &:hover {
      cursor: default;

      .menu {
        display: flex;
      }
    }
  }
}

.menu {
  display: none;
  position: absolute;
  background-color: variables.$clear-bg;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  top: -6px;
  right: 14px;
  overflow: hidden;
  animation: widthOn 0.3s;

  &__option {
    display: flex;
    color: variables.$font-clear;
    cursor: pointer;
    padding: 10px 20px;

    svg {
      font-size: 16px;
      margin-right: 10px;
    }

    &:hover {
      color: variables.$red-color;
      transition: 0.3s;
    }
  }
}

@media (max-width: 768px) {
  .file {
    width: 100%;
    max-width: 100%;
    position: relative;

    &__category {
      flex: 1;
      justify-content: center;
      margin-bottom: 10px;
    }

    > div:first-child {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: 100%;

      > p {
        flex: 1;

        &:nth-child(2) {
          margin-bottom: 10px;
          width: 100%;
          max-width: 100%;
          white-space: wrap;
        }
      }
    }

    &__modify {
      position: absolute;
      bottom: 10px;
      right: 0px;

      > svg {
        transform: rotate(90deg);
      }

      > svg {
        font-size: 20px;
      }
    }
  }

  .menu {
    right: 22px;
    animation: widthOnMobile 0.3s;
  }
}
