@use "styles/variables" as *;

.bottomWrapper {
  margin-top: 50px;
  height: 58svh;
  width: 100%;
  display: flex;
  justify-content: space-between;

  h2 {
    font-size: 18px;
    font-family: "Roboto_bold";
  }

  > div > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    svg {
      width: 24px;
      height: 24px;
      color: $font-clear;
    }
  }

  > div {
    border: 1px solid $border-color;
    border-radius: 15px;
    padding: 20px;
  }
}

.bottomLeft {
  height: 100%;
  width: 40%;

  > a {
    margin-bottom: 30px !important;
  }
}

.bottomRight {
  height: 100%;
  width: 58%;
}

.activities {
  height: calc(100% - 20px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 768px) {
  .bottomWrapper {
    height: fit-content;
    flex-direction: column;
    margin-top: 25px;

    > div {
      width: 100%;
      margin-bottom: 25px;
    }
  }

  .bottomLeft {
    min-height: 240px;
    height: 240px;
    overflow-y: hidden;
  }

  .bottomRight {
    min-height: 300px;
    height: 310px;
    overflow-y: hidden;
  }
}
