@use "styles/variables";

.container {
  width: 32%;
  margin-top: 15px;
  padding: 30px;
  border-radius: 10px;
  cursor: default;
  flex-shrink: 0;
  flex-wrap: wrap;
  position: relative;
  background-color: variables.$clear-bg;
  border: 1px solid variables.$border-color;
  transition: all 0.3s ease-in-out;
  margin-right: 1.3%;

  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
  }

  h3 {
    font-family: "Roboto_bold";
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  p {
    white-space: pre-line !important;
    text-align: justify;
    color: variables.$font-clear;
  }

  svg {
    margin-bottom: 10px;
  }
}

.pointer {
  cursor: pointer;
}

.comingSoon {
  position: absolute;
  background: linear-gradient(125deg, #fca23c 0%, #ffa23a 100%);
  font-size: 10px;
  padding: 4px 10px;
  border-radius: 20px;
  right: 20px;
  top: 20px;

  > p {
    color: variables.$font-white !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    margin-top: 20px;
    padding: 15px;

    h3 {
      margin-bottom: 10px;
    }

    p {
      color: variables.$font-clear;
    }
  }

  .title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
  }
}
