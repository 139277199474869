@use "styles/variables" as *;

.container {
  h4 {
    font-size: 22px;
    margin-bottom: 20px;
  }

  h5 {
    font-size: 18px;
    margin-bottom: 20px;
  }

  > p {
    margin-bottom: 20px;
    text-align: justify;
  }
}

.itemContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;

  &__flexStart {
    justify-content: flex-start !important;

    > div {
      margin-right: 14px;
    }
  }
}

.item {
  padding: 6px 10px;
  margin-bottom: 15px;
  border-radius: 10px;
  width: fit-content;
  background-color: $clear-bg;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.icon {
  display: flex;
  align-items: center;

  > svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
  }

  > p {
    margin-left: 8px;
    font-size: 14px;
    color: $font-base;
  }
}

@media screen and (max-width: 768px) {
  .item {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
}
