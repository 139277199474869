@use "styles/variables" as *;

.wrapper {
  height: calc(100vh - 64px);
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h1 {
    font-size: 45px;
    font-family: "Roboto_bold";
    margin-bottom: 1rem;
  }

  p {
    font-size: 22px;
    margin-bottom: 20px;
  }

  svg {
    width: 28px;
    height: 28px;
    color: $red-color;
    margin-left: 10px;
    margin-bottom: 25px;
  }
}

.container {
  > div {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: 90%;
    margin: auto;

    h1 {
      font-size: 35px;
    }

    a {
      display: block;
      width: 100% !important;

      button {
        width: 100%;
      }
    }
  }

  .container {
    > div {
      flex-direction: column;
    }
  }
}
