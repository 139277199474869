@use "styles/variables" as *;

.quickAccess {
  height: calc(100% - 40px);
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    display: flex;
    align-items: flex-end;
    padding-bottom: 20px;
    margin-bottom: 20px;

    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    > svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }
}

@media (max-width: 768px) {
  .quickAccess {
    height: 100%;
  }
}
