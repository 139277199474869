@use "styles/variables";

.container {
  display: flex;
  align-items: center;
  padding-bottom: 25px;

  h2 {
    width: 100%;
    margin-left: 15px;
    padding-bottom: 10px;
  }

  svg {
    padding-bottom: 8px;
  }
}
