@use "styles/variables" as *;

.container {
  color: $font-clear;
  font-size: 22px;
  margin-bottom: 25px;
  cursor: pointer;
  width: fit-content;
  padding: 5px 0;
  display: flex;
  align-items: center;

  &:hover {
    color: $primary-color;
  }

  p {
    font-size: 16px;
    margin-left: 10px;
  }
}
