@use "styles/variables";

.CustomCheckBox {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    margin-left: 6px;
  }
}
