@use "styles/variables" as *;

.container {
  button {
    width: 100%;
  }
}

.title {
  font-size: 20px;
  font-family: "Roboto_bold";
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  svg {
    font-size: 40px;
    margin-right: 10px;
    color: $primary-color;
  }
}

.errorMessage {
  color: $red-color;
  font-size: 14px;
  margin-bottom: 10px;
  text-align: center;
  font-style: italic;
}
