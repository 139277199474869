@use "variables" as *;
@use "fonts";

/* Globales rules */
body {
  box-sizing: border-box;
  color: $font-base;
  font-family: "Roboto_regular";
}

h1 {
  font-family: "Roboto_bold";
  font-size: 35px;
  margin-bottom: 16px;
}

h3 {
  margin-bottom: 10px;
}

h6 {
  color: $font-clear;
  margin-bottom: 16px;
}

.container {
  width: 100%;
  max-width: 100%;
  padding: 80px 30px 30px 30px;

  > div {
    max-width: 100%;
  }
}

.inDivContainer {
  width: 100%;
  max-width: 100%;
  padding: 0 30px;
}

.dfCenterContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.formContainer {
  display: flex;
  flex-direction: column;
  // padding: 20px;
  background-color: $clear-bg;
  border-radius: 15px;
  // box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  // border: $border-color 1px solid;

  h2 {
    font-size: 24px;
    border-bottom: 1px solid $border-color;
  }

  button {
    margin-left: auto;
    justify-self: flex-end !important;
    align-self: flex-end;
  }
}

.reactMarkDown {
  h1 {
    font-size: 22px;
    margin-top: 0px !important;
    margin-bottom: 0px;
  }

  h2,
  h3 {
    border: none !important;
    margin-bottom: 0px;
    font-size: 22px;
  }

  li {
    margin-bottom: 0px;
    line-height: 1.2;
    list-style: none;
    position: relative;
    margin-left: 20px;
    padding-bottom: 15px;
    color: $font-text;
    border-bottom: 1px $border-color solid;

    &::before {
      content: "";
      position: absolute;
      left: -20px;
      top: 50%;
      transform: translate(0%, -50%);
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: $primary-color;
    }
  }

  p {
    margin-top: 0 !important;
    line-height: 1.3;
    color: $font-text;
  }

  strong {
    font-family: "Roboto_bold";
  }

  table {
    margin-top: 10px;
    margin-bottom: 20px;
    display: block;
  }

  td {
    padding: 15px;
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-bottom-style: solid;
    border-left-style: solid;
    border-bottom-color: $border-color;
    border-left-color: $border-color;

    &:first-child {
      font-weight: bold;
      font-family: "Roboto_bold";
      width: 30%;
    }

    &:last-child {
      border-right-width: 1px;
      border-right-style: solid;
      border-right-color: $border-color;
    }
  }

  thead {
    background-color: $primary-color;
    color: $font-white;
    padding: 20px;
    height: 50px;
  }

  tr > th:first-child {
    border-radius: 10px 0 0 0;
    padding-top: 10px;
  }

  tr > th:last-child {
    border-radius: 0 10px 0 0;
  }
}

.errorMessage {
  color: red;
  margin: auto;
  width: fit-content;
  font-size: 14px;
  margin-top: 5px;
  animation: opacityOn 0.5s ease-in-out;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 5% 5%;
  }

  .inDivContainer {
    padding: 0 5%;
  }

  .reactMarkDown {
    p {
      border: none;
    }

    h1 {
      font-size: 18px;
    }

    h2,
    h3 {
      font-size: 18px;
    }

    thead {
      display: none;
    }

    tr {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    td {
      width: 100%;
      padding: 0;
      padding: 15px 0;
      border: none;
      border-bottom: 1px solid $border-color;

      &:last-child {
        border: none;
        padding-bottom: 30px;
      }
    }

    td:first-child {
      width: 100%;
    }
  }
}
