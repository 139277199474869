@use "styles/variables" as *;

.container {
  margin-top: 20px;
  margin-bottom: 20px;
  color: $font-clear;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    // background-color: $primary-color;
    width: 45%;
    height: 1px;
    opacity: 0.5;
  }
}

@media (max-width: 768px) {
  .container {
    > div {
      width: 40%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
