@use "styles/variables" as *;

.container {
  display: flex;
  margin-top: 45px;
  border-bottom: 1px solid $border-color;

  p {
    position: relative;
    padding-right: 40px;
    padding-bottom: 20px;
    font-family: "Roboto_bold";
    cursor: pointer;
    color: $font-clear;

    &:nth-child(2) {
      display: none;
    }
  }
}

.focus p {
  color: $primary-color !important;

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: calc(100% - 40px);
    height: 2px;
    background: $primary-color;
  }
}

@media screen and (max-width: 768px) {
  .container {
    border: none;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $dark-bg;
    border-radius: 6px;
    width: 100%;
    padding: 4px;

    a {
      flex: 1;

      display: block;
      align-items: center;
      justify-content: center;
      text-align: center;

      &:last-child {
        flex: auto;
        width: 100%;
        margin-top: 4px;
        border-top: 1px solid $border-color;
      }

      > div {
        padding: 5px;
        border-radius: 6px;

        &:last-child {
          margin-top: 4px;
        }
      }
    }

    p {
      padding: 0;
      font-size: 14px;

      &::after {
        content: none;
      }
    }

    p:first-child {
      display: none;
    }

    p:nth-child(2) {
      display: block;
    }
  }

  .focus {
    background-color: $clear-bg;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  }
}
