@use "styles/variables" as *;

.wrapper {
  position: relative;
}

.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  border: 1px solid $border-color;
  border-radius: 6px;
  padding: 10px 8px;
  width: 100%;
  z-index: 1;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s;
}

.input__open {
  border: 1px solid $primary-color;
  border-radius: 6px;

  .icons {
    > svg:last-child {
      fill: $primary-color;
      transform: rotate(180deg);
    }
  }
}

.icons {
  display: flex;
  align-items: center;

  > svg {
    width: 20px;
    height: 20px;
    fill: $font-clear;
    transition: 0.3s;
    margin-left: 10px;
  }
}

.placeholder {
  color: $font-clear;
}

.list {
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background-color: $clear-bg;
  border: 1px solid $border-color;
  border-radius: 6px;
  z-index: 1;
  font-size: 15px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.05);
  padding: 8px;
}

.list__item {
  border-radius: 6px;
  padding: 10px;
  transition: 0.3s;

  &:hover {
    background-color: $primary-tansparent;
    color: $font-base;
  }
}
