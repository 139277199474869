@use "styles/variables";

.container {
  p {
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.selectContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.selectItem {
  width: 100%;
  height: 55px;
  border-radius: 10px;
  border: 1px solid variables.$border-color;
  color: variables.$font-clear;
  font-family: "Roboto_bold";
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &__selected {
    border: 1px solid variables.$primary-color;
    color: variables.$primary-color;
    background-color: variables.$primary-tansparent;
  }
}

@media screen and (max-width: 768px) {
  .container > p {
    font-size: 16px;
  }

  .selectContainer {
    flex-direction: column;
    margin-bottom: 40px;

    .selectItem {
      font-size: 14px;
      height: 45px;

      &:not(:last-child) {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
