@use "styles/variables" as *;

.container {
  display: flex;
  align-items: center;
  width: 310px;
  border: 1px solid $border-color;
  border-radius: 15px;
  padding: 20px;

  p:first-child {
    margin-bottom: 4px;
    font-size: 28px;
    font-family: "Roboto_thin";
  }

  p:nth-child(2) {
    font-size: 16px;
    font-family: "Roboto_bold";
  }

  svg {
    width: 40px;
    height: 40px;
    padding: 6px;
    background: $primary-gradient;
    color: $font-white;
    margin-right: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 4px rgba(39, 152, 252, 0.25);
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
}
