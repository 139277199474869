@use "styles/variables" as *;

@keyframes widthOn {
  0% {
    width: 0%;
  }
  100% {
    width: 620%;
  }
}

.wrapper {
  h3 {
    font-family: "Roboto_bold";
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  h4 {
    color: $font-clear;
    margin-bottom: 25px;
  }

  > p {
    display: flex;
    width: fit-content;
    flex-direction: row-reverse;
    align-items: center;
    color: $primary-color;
    margin-bottom: 30px;
    cursor: pointer;

    svg {
      font-size: 18px;
      padding: 2px;
      margin-left: 10px;
      background: $primary-gradient;
      border-radius: 50%;
      color: $font-white;
    }
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.product {
  width: 24%;
  margin-right: 1%;
  margin-bottom: 1%;
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid $border-color;
  border-radius: 10px;
  cursor: pointer;
  flex-shrink: 0;
  transition: 0.3s;
  position: relative;

  &__description {
    p:first-child {
      font-family: "Roboto_bold";
      margin-bottom: 10px;
    }

    p:nth-child(2) {
      color: $font-clear;
    }
  }
}

.emptyProduct {
  width: 100%;
  margin-top: 20px;
  color: $font-clear;
  font-style: italic;
  text-align: center;
  padding: 0 15px;
}

.openPanel {
  width: 49% !important;
}

.menu {
  display: none;
  position: absolute;
  top: 0;
  right: 25px;
  background-color: $clear-bg;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 10px;

  &__item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: $font-clear;

    > svg {
      margin-right: 5px;
    }

    &:hover {
      color: $red-color;
      transition: 0.3s;
    }
  }
}

.modify {
  position: absolute;
  right: 20px;
  top: 24px;

  > svg {
    transform: rotate(90deg);
    font-size: 22px;
    padding: 4px 0px;
    cursor: default;
  }

  &:hover {
    .menu {
      display: block;
      overflow: hidden;
      animation: widthOn 0.3s;
    }

    > svg {
      color: $primary-color;
      transition: 0.3s;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    h3 {
      margin-top: 30px;
      margin-bottom: 15px;
    }

    h4 {
      color: $font-clear;
      text-align: justify;
    }
  }

  .product {
    width: 100%;
    margin-right: 0%;
    margin-bottom: 20px;
  }

  .openPanel {
    width: 100% !important;
  }
}
