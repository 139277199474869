@use "styles/variables";

.focus {
  transition: 0.3s;
  color: variables.$font-white;
  background-color: #414653;
  background: linear-gradient(-90deg, #3a3f4b 0%, #424753 100%);
  box-shadow: 0px 4px 12px rgba(66, 71, 83, 0.4);
  padding-left: 10px;
  padding-right: 32px;

  svg {
    color: variables.$primary-color;
    filter: drop-shadow(0px 2px 6px rgba(39, 152, 252, 0.3));
  }

  &:hover {
    color: variables.$font-white !important;
    background-color: #414653;
    background: linear-gradient(-90deg, #3a3f4b 0%, #424753 100%);
    filter: drop-shadow(0px 2px 2px rgba(89, 167, 84, 0)) !important;
    cursor: default;
  }
}
