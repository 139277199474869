@use "styles/variables";

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  h2 {
    font-size: 20px;
    font-family: "Roboto_bold";

    svg {
      font-size: 24px;
      margin-right: 10px;
    }
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    svg {
      color: variables.$font-clear;
      font-size: 35px;
    }
  }
}
