@use "styles/variables" as *;

.wrapper {
  margin-top: 35px;
}

.title {
  display: flex;
  align-items: center;

  h5 {
    font-size: 20px;
    font-family: "Roboto_bold";
  }

  p {
    margin: 0;
    margin-left: 10px;
    font-size: 12px;
    color: $font-clear;
    border: 1px solid $border-color;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .wrapper {
    margin-top: 45px;
  }
}
