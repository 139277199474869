@use "styles/variables" as *;

.container {
  width: 40%;

  h3 {
    margin-bottom: 30px;
  }

  h4 {
    margin-bottom: 30px;
    font-family: "Roboto_bold";
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
  }
}
