@use "styles/variables";
@use "styles/animations";

.wrapper {
  position: fixed;
  left: 0;
  top: 0;
  height: 100svh;
  width: 100%;
  backdrop-filter: blur(2px);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: blurOn 0.3s ease-in-out forwards;

  > div {
    background-color: variables.$clear-bg;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: variables.$clear-bg;
    padding: 30px;
    border-radius: 15px;
    animation: topOn 0.3s ease-in-out forwards;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 0;
  }

  .wrapper > div {
    border-radius: 0;
    padding: 40px 20px;
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
