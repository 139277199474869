@font-face {
  font-family: "Roboto_regular";
  src: url("./fonts/Roboto/Roboto-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto_bold";
  src: url("./fonts/Roboto/Roboto-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Roboto_thin";
  src: url("./fonts/Roboto/Roboto-Thin.ttf");
  font-display: swap;
}
