@use "styles/variables";

@keyframes openOn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.container {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  padding: 20px;
  padding-top: 90px;
  width: 50%;
  background-color: variables.$clear-bg;
  white-space: pre-line;
  border-left: 1px solid variables.$border-color;
  border-radius: 0 20px 20px 0px;
  animation: openOn 0.3s ease-in-out;

  &::-webkit-scrollbar {
    display: none;
  }
}

.title {
  font-size: 20px;
  font-family: "Roboto_bold";
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  svg {
    font-size: 24px;
    margin-right: 10px;
  }
}

.infos {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid variables.$border-color;

  span {
    font-family: "Roboto_bold";
  }
}

.content {
  white-space: pre-wrap;
  position: relative;
  border-radius: 10px;

  &:hover {
    .content__modify {
      height: 100px;
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.05);
    }
  }

  &__modify {
    position: absolute;
    right: 4px;
    top: 4px;
    background-color: variables.$dark-bg;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding: 2px;
    border-radius: 20px;
    transition: all 0.3s ease-in-out;
    height: 0;

    &:hover {
      cursor: pointer;
    }

    svg {
      font-size: 20px;
      padding: 10px 12px;
      color: variables.$font-clear;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover {
        color: variables.$primary-color;
      }

      &:last-child:hover {
        color: variables.$red-color;
      }
    }

    &__message {
      position: absolute;
      left: -110%;
      top: 10px;
      font-size: 14px;
      color: variables.$font-clear;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
      animation: opacityOn 0.3s ease-in-out forwards;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    border-radius: 0;
  }
}
