@use "styles/variables" as *;

.wrapper {
  h3 {
    font-family: "Roboto_bold";
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  h4 {
    color: $font-clear;
    margin-bottom: 25px;
  }
}

.section {
  display: flex;
  width: 90%;
  padding-top: 15px;
  padding-bottom: 25px;
  margin-bottom: 20px;

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  &__title {
    width: 50%;

    h5 {
      font-family: "Roboto_bold";
      font-size: 16px;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      color: $font-clear;
    }
  }

  &__right {
    width: 50%;
  }
}

.successMessage {
  color: $green-color;
  font-size: 14px;
  font-style: italic;
  margin-top: 15px;
  animation: opacityOn 0.3s ease-in-out;
}

.errorMessage {
  color: $red-color;
  font-size: 14px;
  font-style: italic;
  margin-top: 15px;
  animation: opacityOn 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .section {
    flex-direction: column;
    width: 100%;

    &__title {
      width: 100%;
      margin-bottom: 15px;
    }

    &__right {
      width: 100%;
    }
  }
}
