@use "styles/variables";
@use "styles/animations";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
}

.container svg {
  width: 50px;
  height: 50px;
  color: variables.$primary-color;
  animation: turnOn 1.4s ease-in-out infinite;
}

.small {
  height: 50px;

  > svg {
    width: 30px;
    height: 30px;
  }
}
