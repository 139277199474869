@use "styles/variables";
@use "styles/animations";

.wrapper {
  height: 100vh;
  width: 100%;
  max-width: 100%;
  background-color: variables.$secondary-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.container {
  width: 100%;
  max-width: 100%;
  height: calc(100vh - 20px);
  margin-right: 10px;
  border-radius: 18px;
  background-color: variables.$clear-bg;
  position: relative;
  max-width: 100%;

  > div {
    max-height: calc(100vh - 20px);
    overflow-x: scroll;
    max-width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    > svg {
      margin-top: 30px;
      margin-left: 86%;
      height: 40px;
      width: 40px;
      display: none;
    }
  }
}

@media (max-width: 900px) {
  .wrapper {
    > div:first-child {
      display: none;
    }
  }

  .container {
    width: 100%;
    max-width: 100%;
    height: 100vh;
    margin-right: 0px;
    border-radius: 0px;
  }

  .container > div {
    max-height: 100vh;
    overflow-x: scroll;

    > svg {
      display: block;
    }
  }
}
