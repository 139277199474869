@use "styles/variables" as *;
@use "styles/animations" as *;

.container {
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 22px;
    font-family: "Roboto_bold";
    animation: opacityOn 0.8s ease-in-out;
  }

  a {
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: $primary-color;
    font-size: 18px;
    opacity: 0;
    animation: opacityOn 0.6s ease-in-out forwards;
    animation-delay: 0.6s;
  }

  svg {
    font-size: 22px;
    padding: 3px;
    margin-left: 10px;
    background: $primary-gradient;
    border-radius: 50%;
    color: $font-white;
    flex-shrink: 0;
    box-shadow: 0px 0px 4px rgba(39, 152, 252, 0.2);
  }
}

@media screen and (max-width: 768px) {
  .container {
    h2 {
      font-size: 28px;
      text-align: center;
    }

    a {
      font-size: 20px;
      text-align: center;
      flex-direction: column;

      svg {
        margin-top: 15px;
      }
    }
  }
}
