@use "styles/variables" as *;

.container > p {
  color: $font-clear;
  font-size: 12px;
  margin-bottom: 20px;
  font-style: italic;
  text-align: right;
  margin-top: 5px;
}

.container > .section {
  margin-bottom: 30px;
}

.firstSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  > a {
    color: $primary-color;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;

    > svg {
      font-size: 18px;
      padding: 2px;
      margin-left: 10px;
      background: $primary-gradient;
      border-radius: 50%;
      color: $font-white;
      flex-shrink: 0;
    }

    > p {
      margin-left: 10px;
    }
  }
}

.section {
  display: flex;
  align-items: center;

  p {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: $secondary-color;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $font-white;
    margin: 0;
    font-size: 12px;
  }

  h3 {
    margin: 0;
    margin-left: 10px;
    font-size: 18px;
  }
}

.emptyProduct {
  margin-top: 40px;
  margin-bottom: 50px;
  color: $primary-color;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > a {
    display: flex;
    align-items: center;
  }

  svg {
    font-size: 18px;
    padding: 2px;
    margin-left: 10px;
    background: $primary-gradient;
    border-radius: 50%;
    color: $font-white;
    flex-shrink: 0;
  }
}

.separator {
  width: 100%;
  height: 1px;
  background: $font-clear;
  opacity: 0.5;
  flex-shrink: 0;
  margin-top: 40px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  .container > p {
    text-align: left;
  }

  .emptyProduct a {
    flex-direction: column;
    text-align: center;

    svg {
      margin-top: 15px;
    }
  }

  .firstSection {
    > a {
      font-size: 12px;
    }

    > a > svg {
      font-size: 14px;
    }
  }
}
