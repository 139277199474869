@use "styles/variables" as *;

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-bottom: 20px;

  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  &__title {
    display: flex;
    align-items: center;

    > svg {
      margin-right: 10px;
      font-size: 20px;
      color: $primary-color;
    }
  }
}
