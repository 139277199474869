@use "styles/variables";

.container {
  p {
    margin-bottom: 35px;
  }

  button {
    margin: auto;
  }
}
