$primary-color: #2798fc;
$secondary-color: #1b1c1f;

$red-color: rgb(241, 76, 97);
$green-color: rgb(32, 193, 144);

$primary-gradient: linear-gradient(125deg, #2798fc 0%, #238dea 100%);
$primary-gradient-2: linear-gradient(125deg, #2798fc 0%, #00baff 50%, #00e8ba 100%);
$primary-tansparent: rgba(39, 152, 252, 0.05);

$font-base: #1e1f1f;
$font-clear: #b0b1bb;
$font-text: #6d7380;
$font-white: #fefeff;

$dark-bg: #f1f2f4;
$clear-bg: #fefeff;

$border-color: #e5e5e8;
