@use "styles/animations" as *;
@use "styles/variables" as *;

.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.layer {
  height: calc(100vh - 20px);
  width: 100%;
  position: absolute;
  z-index: 100;
  border-radius: 18px;
  animation: blurOn 0.5s ease-in-out forwards;
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    background-color: $clear-bg;
    padding: 30px;
    border-radius: 15px;

    svg {
      font-size: 45px;
      margin-bottom: 20px;
      color: $font-clear;
    }

    > p {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
}

.protectedScroll {
  height: calc(100vh - 20px);
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .layer {
    > div {
      width: 90%;
    }
  }
}
