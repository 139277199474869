@use "styles/variables" as *;
@use "styles/animations" as *;

.message {
  font-size: 14px;
  font-style: italic;
  margin-top: 15px;
  animation: opacityOn 0.3s ease-in-out;
}

.successMessage {
  color: $green-color;
}

.errorMessage {
  color: $red-color;
}
