@use "styles/variables";

.container {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100svh;
  z-index: 1000;
  top: 0;
  right: 0;
  background-color: variables.$secondary-color;
  color: variables.$font-white;
  padding: 9% 5%;
  transition: 0.4s;
  box-shadow: -10px 0 10px 0 rgba(0, 0, 0, 0.5);
}

.container__close {
  transform: translateX(100%);
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0);

  .topMenu {
    svg {
      transition: 0.6s;
      transition-delay: 0.2s;
      transform: rotate(0deg);
    }
  }

  .menu {
    li {
      font-size: 28px;
      padding: 18px 35px;
      opacity: 0;
    }

    a {
      transition: none;

      &:first-child {
        li {
          transform: translateX(50%);
          opacity: 0;
        }
      }

      &:nth-child(2) {
        li {
          transform: translateX(50%);
          opacity: 0;
        }
      }

      &:nth-child(3) {
        li {
          transform: translateX(50%);
          opacity: 0;
        }
      }

      &:nth-child(4) {
        li {
          transform: translateX(50%);
          opacity: 0;
        }
      }
    }
  }
}

.topMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  > svg {
    transition: 0.8s;
    width: 45px;
    height: 45px;
    transform: rotate(45deg);
  }
}

.boxLogo {
  position: relative;

  span {
    font-size: 22px;
    font-family: "Roboto_bold";

    &:nth-child(2) {
      font-size: 12px;
      border: 1px solid variables.$primary-color;
      border-radius: 25px;
      padding: 2px 12px;
      left: 140px;
      top: 6px;
      position: absolute;
      color: variables.$primary-color;
      filter: drop-shadow(0px 0px 2px rgba(39, 152, 252, 0.35));
      box-shadow: 0px 0px 4px rgba(39, 152, 252, 0.25);
    }
  }
}

.menu {
  li {
    transition: all 0.8s;
    padding: 18px 0px;
    opacity: 1;
    font-size: 28px;
  }

  a {
    &:first-child {
      li {
        transition-delay: 0s;
        opacity: 1;
      }
    }

    &:nth-child(2) {
      li {
        transition-delay: 0.1s;
        opacity: 1;
      }
    }

    &:nth-child(3) {
      li {
        transition-delay: 0.2s;
        opacity: 1;
      }
    }

    &:nth-child(4) {
      li {
        transition-delay: 0.3s;
        opacity: 1;
      }
    }
  }
}

.lastMenu {
  svg {
    width: 25px;
    height: 25px;
  }
}
