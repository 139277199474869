@use "styles/variables" as *;

.billing {
  width: 100%;
  border-radius: 10px;
  border: 1px solid $border-color;
  height: fit-content;

  &:first-child {
    margin-right: 20px;
  }

  button {
    margin-top: 20px;
    width: 100%;
  }
}

.title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid $border-color;
  padding: 20px;

  h5 {
    font-size: 20px;
    font-family: "Roboto_bold";
    margin-bottom: 8px;
  }

  p {
    color: $font-clear;
  }

  > p {
    font-size: 25px;
    font-family: "Roboto_bold";
    color: $secondary-color;

    span {
      font-size: 14px;
      color: $font-clear;
      font-family: "Roboto_regular";
    }
  }
}

.button {
  padding: 0px 20px 20px 20px;
  border-top: 1px solid $border-color;
}

.advantages {
  // border-bottom: 1px solid $border-color;
  padding: 15px 20px;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  svg {
    font-size: 20px;
    margin-right: 6px;
  }
}

.icon__yes {
  color: $green-color;
}

.icon__no {
  color: $red-color;
}

@media screen and (max-width: 768px) {
  .billing {
    margin: 0;
    margin-bottom: 20px;
  }
}
