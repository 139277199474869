@use "styles/variables";

.history h1 {
  margin-top: 80px;
}

.history__between {
  width: 50%;
  max-width: 50%;
}

.folderWrapper {
  margin-top: 45px;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
  max-width: 100%;
  overflow-x: scroll;
  padding-left: 30px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.files__wrapper {
  border-radius: 20px;
  // padding: 30px 20px;

  h3 {
    font-size: 20px;
    font-family: "Roboto_bold";
    margin-bottom: 20px;
  }
}

.files__container {
  height: 100%;
  max-height: 100%;
  max-width: 100%;
}

.files__display {
  height: calc(100% - 70px);
  overflow-y: scroll;
  max-height: 100%;
  max-width: 100%;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.emptyProduct {
  margin-top: 70px;
  font-size: 14px;
  text-align: center;
  color: variables.$font-clear;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .history {
    margin-top: 5%;
  }

  .folderWrapper {
    padding-left: 5%;
  }
}
