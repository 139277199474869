@use "styles/variables";

.HistoryGridHeader {
  color: variables.$font-clear;
  padding-bottom: 6px;
  // border-bottom: 2px solid variables.$border-color;
  font-size: 12px;

  > div {
    display: flex;
    align-items: center;
    width: 95%;
  }

  div > p:first-child {
    flex: 0.2;
  }

  div > p:nth-child(2) {
    flex: 0.7;

    margin-right: 10px;
  }

  div > p:nth-child(3) {
    flex: 0.1;
  }

  p:nth-child(4) {
    flex: 0.5;
  }
}

@media screen and (max-width: 768px) {
  .HistoryGridHeader {
    display: none;
  }
}
