@use "styles/variables" as *;

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $clear-bg;
  border: 1px solid $border-color;
  // box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.06);
  padding: 15px;
  border-radius: 10px;
  width: 200px;
  height: 140px;
  flex-shrink: 0;

  &:not(:last-child) {
    margin-right: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  svg {
    color: $primary-color;
    font-size: 40px;
    filter: drop-shadow(0px 2px 2px rgba(39, 152, 252, 0.3));
  }

  p:first-child {
    font-size: 16px;
    font-family: "Roboto_bold";
    margin-bottom: 5px;
  }

  p:nth-child(2) {
    font-size: 14px;
    color: $font-clear;
    margin-bottom: 5px;
  }
}

.focus {
  border: 1px solid $primary-color;
}

@media screen and (max-width: 768px) {
  .container:not(:last-child) {
    margin-right: 10px;
  }
}
